<template>
<div class="allreviews-container">
    <div style="width: 100%; display: flex; align-items: center; gap: 10px;">
        <img v-if="poi != null" :src="targets[poi.target_id]" style="width: auto; height: 64px;" />
        <div style="display: flex; justify-content: center; flex-direction: column; gap: 5px;">
            <h3 style="margin: 0;">{{ poi != null ? poi.name : '..' }}</h3>
            <div style="display: flex; justify-content: flex-start; align-items: center; gap: 10px;">
                <Stars :stars="poi != null ? Math.floor(poi.average_rating) : 0" />
                <p style="margin: 0;" class="light">{{ poi != null ? poi.number_of_reviews : 0 }} {{ $t('common.reviews') }}</p>
            </div>
        </div>
    </div>
    <Reviews :poi="{id: $route.params.id}" :user="user"/>
</div>
</template>

<script>
import Reviews from '../Reviews.vue';
import Stars from '../Stars.vue';
import { baseUri, apiCall } from '../../utils/ApiMiddleware';

export default {
    name: "allreviews",
    props: {
        

        user: {
            type: Object,
            default: function(){
                return null;
            }
        }
    },
    components: {
        Stars,
        Reviews
    },

    computed: {
        targets(){
            return this.$TARGETS.icons;
        }
    },

    methods: {
        async getPoi(id) {
            const loading = this.$vs.loading();
            const response = await apiCall('GET', '/pois/' + id);
            if (response.status == 200) {
                this.poi = response.data;
            } else if (response.status != 0) {
                this.$vs.notification({
                    title: this.$t('common.messages.somethingWentWrong'),
                    text: this.$t('poi.messages.cannotLoadPoi'),
                    color: 'danger',
                    position: 'top-right'
                })
            }
            loading.close();
        },
    },

    data(){
        return {
            baseUri,
            poi: null
        }
    },

    async mounted(){
        await this.getPoi(this.$route.params.id)
    }
}
</script>

<style scoped>
.allreviews-container {

    display: flex;
    flex-direction: column;
    width: 100%;
    gap: 20px;
}

/* MOBILE */
@media (max-width: 600px) {
    .allreviews-container {
        padding-top: 20px;
        padding-left: 20px;
        padding-right: 20px;
        box-sizing: border-box;
        padding-bottom: 80px!important;
height: unset!important;
        width: 100%!important;
    }

}
</style>
